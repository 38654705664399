import { render, staticRenderFns } from "./singerAlbum.vue?vue&type=template&id=7e8d1baa&scoped=true&"
import script from "./singerAlbum.vue?vue&type=script&lang=js&"
export * from "./singerAlbum.vue?vue&type=script&lang=js&"
import style0 from "./singerAlbum.vue?vue&type=style&index=0&id=7e8d1baa&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/_vue-loader@15.9.8@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7e8d1baa",
  null
  
)

export default component.exports